import { useEffect, useState } from 'react';
import {
  RELATIVE_LINKS_PROD,
  RELATIVE_PAGE_PATHS,
  USER_DATA_IN_LOCAL_STORAGE,
} from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import AdSlot from './AdSlot';
import { useSelector } from 'react-redux';
import { getAxiosWithToken } from '../../utils/webApi';

const AdSlotSpecificPageFooter = () => {
  const [isSubscribedToPaidStickers, setIsSubscribedToPaidStickers] =
    useState(false);
  const [isUsingFreeTrial, setIsUsingFreeTrial] = useState(false);
  const location = useLocation();
  const loggedInUserEmail = useSelector(
    (state: any) => state.authentication.userData.email
  );

  useEffect(() => {
    const user = localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE);
    if (user) {
      const userData = JSON.parse(user);
      setIsSubscribedToPaidStickers(userData.isSubscribedToPaidStickers);
      checkUserSubscription();
    }
  }, []);

  const checkUserSubscription = async () => {
    const subscriptionResponse = await getAxiosWithToken().post(
      'billing/user-subscriptions',
      { email: loggedInUserEmail }
    );
    const subscription = subscriptionResponse.data.subscriptions
      ? subscriptionResponse.data.subscriptions[0]
      : null;
    if (subscription && subscription.status === 'trialing') {
      setIsUsingFreeTrial(true);
    }
  };

  if (!localStorage.getItem(USER_DATA_IN_LOCAL_STORAGE)) {
    return null;
  }

  const adTypeComponent = () => {
    switch (location.pathname) {
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.MEDICINE}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Medical_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      case RELATIVE_PAGE_PATHS.CRY:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Cry_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.FEEDING}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Feeding_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.WEIGHT_TRACKER}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Weight_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.BATHING}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Bathing_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.TRACKING}/${RELATIVE_LINKS_PROD.TEMPERATURE}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Temperature_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      case `${RELATIVE_PAGE_PATHS.SLEEP}`:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Sleep_Tracker_Footer"
            size={[[300, 50]]}
          />
        );
      default:
        return (
          <AdSlot
            id="div-gpt-ad-1668693543692-1"
            path="/21663792601/Footer_Banner"
            size={[[300, 50]]}
          />
        );
    }
  };

  return (
    <>
      {(!isSubscribedToPaidStickers || isUsingFreeTrial) && adTypeComponent()}
    </>
  );
};

export default AdSlotSpecificPageFooter;
